export const parseProcedureType = (type) => {
  switch(type) {
    case 'PROCEDURE':
      return 'Procedimento';
    case 'TELEMEDICINE':
      return 'Telemedicina';
    case 'APPOINTMENT':
      return 'Presencial';
      case 'RETURN':
        return 'Retorno';
      case 'EXAM':
        return 'Exame';
      case 'SURGICAL':
        return 'Centro cirúrgico';
    default:
      return type;
  }
};
