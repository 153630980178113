<template>
  <div class="container">
    <span v-if="!hideIcon" class="fc-icon">
      <Procedure v-if="clinicProcedure.type == 'PROCEDURE'" />
      <Appointment v-if="clinicProcedure.type == 'APPOINTMENT'" />
      <Telemedicine v-if="clinicProcedure.type == 'TELEMEDICINE'" />
      <Return v-if="clinicProcedure.type == 'RETURN'" />
      <Exam v-if="clinicProcedure.type == 'EXAM'" />
      <Surgical v-if="clinicProcedure.type == 'SURGICAL'" class="surgery-icon"/>
      <HelpCircle v-if="clinicProcedure.type === null" />
    </span>
    <span v-if="!hideType" class='procedure-text'>
      <span v-if="clinicProcedure.type == 'TELEMEDICINE'">Telemedicina {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == 'APPOINTMENT'">Presencial {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == 'PROCEDURE'">Procedimento {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == 'RETURN'">Retorno {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == 'EXAM'">Exame {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == null">Indefinido {{hideName ? '' : '| '}} </span>
      <span v-if="clinicProcedure.type == 'SURGICAL'">Centro cirúrgico {{hideName ? '' : '| '}} </span>
    </span>
    <span v-if="!hideName" class='procedure-name'>{{ codeVisible ? clinicProcedure.code + ' - ' + clinicProcedure.name : clinicProcedure.name }}</span>
  </div>
</template>

<script>
import Procedure from '@/assets/icons/procedure.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Return from '@/assets/icons/return.svg'
import HelpCircle from '@/assets/icons/help-circle-filled.svg'
import Exam from '@/assets/icons/exam.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  name: 'ProcedureLine-component',
  components: {
    Procedure,
    Appointment,
    Telemedicine,
    Return,
    HelpCircle,
    Exam,
    Surgical
  },
  props: {
    hideIcon: {
      type: Boolean,
      default: false,
    },
    hideType: {
      type: Boolean,
      default: false,
    },
    hideName: {
      type: Boolean,
      default: false,
    },
    clinicProcedure: {
      type: Object,
      required: true,
    },
    codeVisible: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  margin: 0;
  padding: 0;
}

.fc-icon {
  margin-right: 10px;

  svg {
    width: 24px;
    height: 24px;
    transform: scale(0.8); 
    fill: #305BF2;
  }
}

.procedure-text {
  color: #305BF2;
}

.procedure-name {
  color: var(--type-active);
}

</style>
