<template>
  <div class="rooms">
    <div class="header">
      <p class="title">Salas e setores</p>
      <div class="actions">
        <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && loadClinicRooms()"/>
        <b-button
          v-can="'CcSal2'"
          variant="primary"
          @click="$bvModal.show('room-form-modal')"
        >
          Nova sala ou setor
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <b-form-group class="form-group">
          <label for="search">Salas ou setores</label>
          <b-form-input
            autocomplete="off"
            class="placeholder-color"
            id="search"
            debounce="500"
            placeholder="Buscar sala e setores"
            v-model="query"
          />
        </b-form-group>
      </div>
      <div class="col-6">
        <b-form-group class="form-group">
          <label for="procedure">Procedimento</label>
          <v-autocomplete
            placeholder="Selecionar"
            debounce="500"
            v-model="itemId"
            v-on:typing="(value) => this.getItems(value)"
            :options="this.getClinicProceduresOptions()"
          />
        </b-form-group>
      </div>
    </div>

    <ClinicRoomsTable
      :clinic-rooms="clinicRooms"
      :onUpdate="updateClinicRoom"
      :onDelete="deleteClinicRoom"
    />

    <div class="pagination-position">
      <b-pagination
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
      />
    </div>

    <v-room-form-modal
      :clinicRoom="clinicRoom"
      :loadClinicRooms="() => loadClinicRooms()"
      @clearClinicRoom="clearClinicRoom"
      @changeRoom="changeRoom"
    />

  </div>
</template>

<script>
import itemApi from '@items/api'
import * as ITEM from '@items/utils/constants'
import { debounce } from 'lodash';
import Autocomplete from '@/components/Autocomplete'
import RoomFormModal from '@/components/Room/RoomFormModal'
import { parseProcedureType } from '@/utils/procedure-helper.js'

export default {
  name: 'RoomsView',
  components: {
    Loading: () => import('@/assets/icons/loading.svg'),
    ClinicRoomsTable: () => import('@/components/Room/ClinicRoomsTable'),
    'v-room-form-modal': RoomFormModal,
    'v-autocomplete': Autocomplete,
  },
  created() {
    this.debounceGetClinicProcedures = debounce(this.getItems, 300);
    this.loadClinicRooms()
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      loading: false,
      page: 1,
      limit: 0,
      count: 0,
      query: null,
      itemId: null,
      clinicRooms: [],
      clinicRoom: null,
      items: [],
      allowedTypes: [
        ITEM.ITEM_TYPE_PROCEDURE,
        ITEM.ITEM_TYPE_APPOINTMENT,
        ITEM.ITEM_TYPE_EXAM,
        ITEM.ITEM_TYPE_SURGICAL,
        ITEM.ITEM_TYPE_RETURN,
        ITEM.ITEM_TYPE_TELEMEDICINE,
      ]
    }
  },
  methods: {
    getClinicProceduresOptions() {
      return this.items.map(p => ({ label: `${this.parseProcedureType(p.type)} | ${p.name}`, value: p.id }));
    },
    returnRoom() {
      return this.clinicRoom
    },
    changeRoom(clinicRoom) {
      this.clinicRoom = clinicRoom
    },
    parseProcedureType,
    getItems(query) {
      if (!query) return;
      itemApi.searchItems(this.clinic.id, query, this.allowedTypes)
        .then(res => {
          this.items = res.data;
      })
    },
    clearClinicRoom() {
      this.clinicRoom = null
    },
    loadClinicRooms() {
      this.loading = true;
      this.api
        .getClinicRooms(this.clinic.id, this.page, this.query, this.itemId)
        .then(({ data }) => {
          this.clinicRooms = data.data;
          this.limit = data.per_page;
          this.count = data.total;
        })
        .catch(err => {
          console.log('Err', err)
        })
        .finally(() => {
          this.loading = false;
        })
    },
    updateClinicRoom(clinicRoom) {
      this.clinicRoom = Object.assign({}, clinicRoom);
      setTimeout(() => {
        this.$bvModal.show('room-form-modal');
      }, 500);
    },
    deleteClinicRoom(clinicRoom) {
      this.loading = true;
      this.api.deleteClinicRoom(clinicRoom.id)
      .then(() => {
        this.loadClinicRooms();
        this.$toast.success('Sala ou setor excluído com sucesso!');
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
      });
    }
  },
  watch: {
    query: function() { this. loadClinicRooms() },
    page: function() { this. loadClinicRooms() },
    itemId: function() { this. loadClinicRooms() },
  }
}
</script>

<style lang="scss" scoped>
.rooms {
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .title {
      margin-bottom: 0 !important;
    }
  }

  .placeholder-color::placeholder {
    color: var(--type-placeholder);
  }

  .pagination-position {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
  }
  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}
</style>
