<template>
  <b-modal
    id="room-form-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @hidden="closeModal"
    @show="openModal"
    :no-close-on-backdrop="true"
  >
    <div class="modal-header">
      <div class="modal-title">{{ this.form.id ? 'Editar' : 'Adicionar'}} sala ou setor</div>
      <span class="icon-box"><v-close class="icon stroke" @click="closeModal"  /></span>
    </div>

    <div class="content">
      <div class="row">
        <b-col cols="6">
          <b-form-group
            :invalid-feedback="errors.name"
            :state="!errors.name"
          >
              <label for="name">Nome da sala ou setor</label>
              <b-form-input
                autocomplete="off"
                class="placeholder-color"
                trim
                id="name"
                placeholder="Digite o nome da sala ou setor de atendimento"
                v-model="form.name"
              />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <label for="type">Tipo de sala</label>
            <multiselect
              v-model="form.type"
              id="type"
              placeholder="Selecionar"
              :options="roomTypes"
              :showLabels="false"
              :internal-search="false"
              :disabled="!!clinicRoom"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>
              <template slot="noResult">Nenhum tipo de sala encontrado</template>
            </multiselect>
          </b-form-group>
        </b-col>
        <div v-if="clinicRoom" class="col-12">
          <b-form-group>
            <label for="procedure">Buscar por procedimento</label>
            <multiselect
              id="procedure"
              track-by="id"
              label="name"
              placeholder="Busque por um procedimento"
              :options="itemOptions"
              :showLabels="false"
              :internal-search="false"
              :disabled="!form.id"
              class="search-mode with-border"
              @search-change="debounceGetClinicProcedures"
              @select="addItemToRoom"
            >
              <template slot="caret">
                <div class="search">
                  <v-search />
                </div>
              </template>

              <template slot="option" slot-scope="props">
                <v-procedure-line :clinicProcedure="props.option" codeVisible/>
              </template>

              <template slot="noResult">Nenhum procedimento encontrado</template>
              <template slot="noOptions">Digite para pesquisar um procedimento</template>
            </multiselect>
          </b-form-group>
        </div>
      </div>
      <div v-if="clinicRoom" class="col-12">
        <table class="table-this">
          <thead>
            <th>Procedimento<v-up-down-arrows class="sorting-icon"/></th>
            <th>Tipo</th>
            <th></th>
          </thead>
          <tbody>
            <tr v-for="item of items" :key="item.id">
              <td><v-procedure-line :clinicProcedure="item" codeVisible/></td>
              <td><v-procedure-line :clinicProcedure="item" hideName/></td>
              <td><v-trash-icon @click="openConfirmExclusionModal(item)"/></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="clinicRoom && !items.length" class="col-12 no-procedures-in-room">
        Busque por um procedimento e selecione
      </div>
      <div class="d-flex save">
        <b-button
          v-if="!clinicRoom?.id"
          variant="primary"
          class="mt-8"
          @click="createOrUpdateClinicRoom"
        >
          Salvar
        </b-button>
      </div>
    </div>
    <v-confirm-procedure-deletion
      :procedure="toDelete"
      :confirmedDelete="confirmedDelete"
      />
  </b-modal>
</template>
<script>
import * as ITEM from '@items/utils/constants'
import itemApi from '@items/api'
import { debounce } from 'lodash';
import { showFeature } from '@/utils/permissionsHelp'

import Close from '@/assets/icons/close.svg'
import Search from '@/assets/icons/search.svg'
import UpDownArrows from '@/assets/icons/up-down-arrows.svg'
import ProcedureLine from '@/components/Procedure/ProcedureLine.vue'
import Trash from '@/assets/icons/trash.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import ConfirmProcedureDeletion from '@/components/Room/ConfirmProcedureDeletion.vue'

export default {
  name: 'RoomFormModal',
  components: {
    'v-close': Close,
    'v-search': Search,
    'v-up-down-arrows': UpDownArrows,
    'v-procedure-line': ProcedureLine,
    'v-trash-icon': Trash,
    'v-chevron-down': ChevronDown,
    'v-confirm-procedure-deletion': ConfirmProcedureDeletion,
  },
  props: {
    clinicRoom: Object,
    loadClinicRooms: Function,
  },
  created() {
    this.debounceGetClinicProcedures = debounce(this.getProcedures, 300);
    this.debounceCreateOrUpdateClinicRoom = debounce(this.createOrUpdateClinicRoom, 500);
  },
  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      form: {},
      items: [],
      errors: {},
      itemOptions: [],
      toDelete: null,
      roomTypes: ['Exames/Procedimentos'],
    }
  },
  mounted(){
    this.updateRoomTypes()
  },
  methods: {
    confirmedDelete(procedure) {
      if(procedure) {
        this.api.deleteProcedureFromRoom(procedure.pivot.id)
          .then(() => {
            this.items = this.items.filter(p => p.pivot.id !== procedure.pivot.id);
            this.$toast.success('Procedimento removido da sala com sucesso!');
          })
          .catch(() => {
            this.$toast.error('Erro ao remover procedimento da sala!');
          });
      }
      this.toDelete = null;
      this.$bvModal.hide('confirm-procedure-deletion');
    },
    async createOrUpdateClinicRoom(name) {
      const props = this.generateProps();
      if (!this.isValid()) return;
      try {
        if (this.form.id) {
          await this.updateClinicRoom(props);
          this.$toast.success('Sala ou setor atualizado com sucesso!');
        } else {
          const clinicRoom = await this.createClinicRoom(props);
          this.$emit('changeRoom',clinicRoom)
          this.form = {...this.form, id: clinicRoom.id};
          this.$toast.success('Sala ou setor criado com sucesso!');
        }
      } catch (ex) {
        this.$toast.error('Erro ao criar sala ou setor!');
      }
    },
    createClinicRoom(props) {
      return new Promise((resolve, reject) => {
        this.api.createClinicRoom(props)
          .then(res => resolve(res.data))
          .catch(reject);
      });
    },
    updateClinicRoom(props) {
      return new Promise((resolve, reject) => {
        this.api.updateClinicRoom(this.form.id, props)
          .then(res => resolve(res.data))
          .catch(reject);
      });
    },
    isValid() {
      const err = {};
      if (!this.form.name || this.form.name.length === 0) {
        err.name = 'Nome da sala ou setor é obrigatório';
      }

      this.errors = err;
      return !Object.keys(err).some(key => !!err[key]);
    },
    generateProps() {
      return {
        clinic_id: this.clinic.id,
        name: this.form.name,
        type: this.form.type,
      }
    },
    generatePropsForProcedure(item) {
      return {
        clinic_id: this.clinic.id,
        room_id: this.form.id,
        item_id: item.id,
      }
    },
    getProcedures(query) {
      if(!this.clinicRoom) return;
      const types = {
        'Exames/Procedimentos': [ ITEM.ITEM_TYPE_PROCEDURE, ITEM.ITEM_TYPE_APPOINTMENT, ITEM.ITEM_TYPE_EXAM, ITEM.ITEM_TYPE_RETURN, ITEM.ITEM_TYPE_TELEMEDICINE],
        'Centro cirúrgico': [ITEM.ITEM_TYPE_SURGICAL],
      }
      itemApi.searchItems(this.clinic.id, query, types[this.clinicRoom.type])
        .then(res => {
          this.itemOptions = res.data;
      })
    },
    clearForm() {
      this.form = {};
      this.items = [];
      this.errors = {};
    },
    closeModal() {
      if(this.clinicRoom?.name !== this.form.name) {
        this.updateClinicRoom(this.form);
      }
      this.clearForm();
      this.$bvModal.hide('room-form-modal');
      this.$emit('clearClinicRoom');
      this.loadClinicRooms();
    },
    openModal() {
      this.form = {
        id: this.clinicRoom?.id,
        name: this.clinicRoom?.name,
        type: this.clinicRoom?.type,
      };
      this.itemOptions = []
      this.items = this.clinicRoom?.items || [];
    },
    addItemToRoom(item) {
      const foundItem = this.items.find(el => el.id === item.id);
      if (foundItem) {
        this.$toast.info('Item já cadastrado nesta sala!');
        return;
      }
      const props = this.generatePropsForProcedure(item);
      this.api.addClinicProcedureToRoom(props)
      .then(() => {
        this.items.push(item);
        this.$toast.success('Item adicionado à sala com sucesso!');
      });
    },
    openConfirmExclusionModal(procedure) {
      this.toDelete = procedure;
      this.$bvModal.show('confirm-procedure-deletion');
    },
    deleteProcedureFromRoom(clinicProcedure) {
      const findProcedureIndex = this.items.
        findIndex((procedure) => {
          return procedure.pivot.id === clinicProcedure.pivot.id
        });
      if (findProcedureIndex === -1) {
        this.$toast.info('Procedimento não encontrado nesta sala!');
      }
    },
    updateRoomTypes() {
      if (showFeature('Centro cirúrgico')) {
        this.roomTypes.push('Centro cirúrgico');
      }
    }
  },
}
</script>

<style lang="scss">
#room-form-modal {
  .save {
    justify-content: end;
  }
  .modal-content {
    .modal-body {
      padding: 0;
      font-family: Nunito Sans;
      // font-style: normal;
      // font-weight: 600;
    }
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px;
      padding-bottom: 19px;
    }
    .modal-title {
      color: var(--type-active);
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
    .icon {
      width: 18px;
      fill: var(--type-placeholder)
    }
  }
  .content {
    padding: 24px;

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .button {
      padding: 30px 0 0 0;
      text-align: right;
    }
  }

  .table-this {
    width: 100%;
    margin-bottom: 24px;
    font-size: 16px;

    thead {
      background-color: var(--neutral-100);
    }

    th {
      padding-top: 10px;
      padding-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      color: var(--type-active);
    }

    th:first-child, td:first-child {
      padding-left: 20px;
    }

    th:last-child, td:last-child {
      padding-right: 20px;
    }

    .sorting-icon {
      margin-left: 10px;
      margin-bottom: 5px;
      fill: var(--neutral-500);
      // width: 24px;
    }

    td {
      border-bottom: 1px dashed #C6CEEB;
      padding-top: 20px;
      padding-bottom: 20px;
    }

    tr:nth-child(2n) {
      background-color: #F8F8FD;
    }

    td:nth-child(1) {
      width: 70ch;
    }

    td:nth-child(3) {
      width: 20px;

      svg {
        height: 20px;
        stroke: #FBA59D;
        fill: transparent;
        cursor: pointer;
      }
    }

    tr:hover {
      background-color: var(--neutral-100);
    }
  }

  .no-procedures-in-room {
    font-size: 16px;
    color: var(--type-placeholder);
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;
  }
}
</style>
